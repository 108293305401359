import { getFallbackWebLoanApplicationUrl, getMigratedSteps, isFeatureRedirectionToFallbackUrlEnabled } from '@/config';
import { pages } from '@/constants';

export const isRedirectionRequired = ({ routeName, prevRouteName, skipCheckingRouteName } = {}) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const isStepAlreadyMigrated = getMigratedSteps().includes(routeName);

  return !!(
    isFeatureRedirectionToFallbackUrlEnabled() &&
    fallbackWebLoanApplicationUrl &&
    (
      skipCheckingRouteName ||
      isStepAlreadyMigrated ||
      prevRouteName === pages.createApplication
    )
  );
};

export const redirectToFallbackWebLoanApplicationUrl = (fullPath) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const params = fullPath.substring(fullPath.indexOf('?'));

  window.location.href = `${fallbackWebLoanApplicationUrl}${params}`;
};
